<template>
  <div class="cen">
    <div class="d-flex flex-column min-vh-100">
      <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider
          theme="light"
          class="contents"
          v-model="collapsed"
          :trigger="null"
          collapsible
        >
          <div class="logo">
            <router-link class="nav-link" to="/">
              <a-icon type="home" :style="{ fontSize: '28px' }" />
            </router-link>
          </div>          
          <a-menu
            theme="light"
            mode="inline"
            :default-open-keys="openKeys"
            :default-selected-keys="['1']"
          >
            <a-sub-menu style="text-align: left;" v-for="item in Getmenus" :key="item.id">
              <span slot="title" >
                <a-icon :type="item.icon" />
                <span v-text="item.title"></span>
              </span>
              <a-menu-item v-for="obj in item.acts_detailpermission" :key="obj.id">                 
                 <router-link :to="{name:obj.vals}">{{obj.title}}</router-link>
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header class="contents">
            <div style="float:left;">   
              <b-icon class="trigger" @click="() => (collapsed = !collapsed)" 
              scale="1.4" :icon="collapsed ?'text-indent-right':'text-indent-left'" variant="muted"></b-icon>           
              <router-link :to="{name:'Center'}"><span v-text="'管理中心'" style="font-size:18px" /></router-link>
             &emsp; 選擇主辦身份 &ensp;
            <a-select  :defaultValue="itemval" v-model="itemval" size="large" style="width: 300px" @change="handleChange">
              <a-select-option v-for="item in orgdata" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>        
              &ensp; <span v-if="defaultval.state=='N'" v-text="'未審核(主辦單位)'"></span>
              <span  v-if="mylevel!==null" v-text="mylevel.mydata.name=='admin' || mylevel.mydata.name==''?'':'上層 : '+mylevel.mydata.name" class="my-span"></span>   
            </div>
            <div style="float:right;padding-right:30px;">
              <b-dropdown  size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                      <b-avatar
                        :badge="gemess"  
                        badge-offset="-1.4em" 
                        badge-variant="danger"                      
                        variant="secondary"
                        :size="27"
                      ><b-icon icon="chat-quote-fill" variant="light"></b-icon>
                      </b-avatar>
                </template>
                <b-dropdown-text style="width: 300px;padding:0;"> 
                  <b-icon icon="chat-quote-fill" variant="success"></b-icon>&ensp;訊息
                </b-dropdown-text>                
                  <b-dropdown-group style="padding:10px;">
                    <a-list item-layout="horizontal"  :data-source="items">
                      <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta
                          description=""
                        >
                          <a slot="title" style="margin-left:-0.5rem;" href="#">
                          <router-link :to="{name:'Info_detail',params:{id:item.id}}">{{ txtmess(item.title,15)  }}</router-link>
                          </a> 
                          <b-avatar variant="success" size="sm" slot="avatar" icon="envelope" />
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#">
                      <router-link :to="{name:'Info_center'}">訊息/公告中心</router-link>
                    </b-dropdown-item>
                  </b-dropdown-group>
              </b-dropdown>    

              <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                      <b-avatar
                        :badge="alertmess" 
                        badge-offset="-1.4em" 
                        badge-variant="danger"                      
                        variant="secondary"
                        :size="27"
                      ><b-icon icon="bell-fill" variant="light"></b-icon>
                      </b-avatar>
                </template>

                <b-dropdown-text style="width: 300px;padding:0;"> 
                  <b-icon icon="bell-fill" variant="warning"></b-icon>&ensp;公告
                </b-dropdown-text>                
                  <b-dropdown-group style="padding:10px;">
                    <a-list item-layout="horizontal"  :data-source="altdata">
                      <a-list-item slot="renderItem" slot-scope="item">
                        <a-list-item-meta
                          description=""
                        >
                          <a slot="title" style="margin-left:-0.5rem;" href="#">                           
                          <router-link :to="{name:'Info_detail',params:{id:item.id}}">{{ txtmess(item.title,15)  }}</router-link>
                          </a>
                          <b-avatar variant="warning" style="color: #ffffff;margin-top:-0.001rem;" size="sm" slot="avatar" icon="calendar2-check-fill" />
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#">
                      <router-link :to="{name:'Info_center'}">訊息/公告中心</router-link>
                    </b-dropdown-item>
                  </b-dropdown-group>

              </b-dropdown>

            </div>
          </a-layout-header>
          <a-layout-content class="ddic" :style="{ margin: '24px 16px', padding: '10px', background: '#fff', minHeight: '280px' }" >
          <router-view></router-view>
          </a-layout-content>
          <a-layout-footer class="contents">
            <span style="color:#b2b5b6;">≡.Design：宸緯資訊有限公司</span>
          </a-layout-footer>
        </a-layout>
      </a-layout>
        <b-alert
          v-model="showTop"
          class="position-fixed fixed-bottom m-0 rounded-0"
          style="z-index: 2000;"
          variant="warning"
          dismissible
        >
          請確認是否有公告/訊息, 尚未處理謝謝
        </b-alert>      
    </div>
  </div>
</template>
<script>
import router from "../router";
import { mapState, mapActions,mapMutations } from "vuex";

/*const getNotificationStyle = type => {
  return {
    success: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #b7eb8f',
      backgroundColor: '#f6ffed'
    },
    warning: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffe58f',
      backgroundColor: '#fffbe6'
    },
    error: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffa39e',
      backgroundColor: '#fff1f0'
    },
    info: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #91d5ff',
      backgroundColor: '#e6f7ff'
    }
  }[type]
}*/


export default {
  name: "Center",
  components: {},
  data() {
    return {
      collapsed: false,
      //openKeys: ["a1", "a2", "a3", "a4", "a5"],
      openKeys: [],
      disableds: false,
      visibley: false,
      messsage: null,
      title: { title: "主辦活動", logo: "menu-unfold" },
      current: 0,
      stepdata: [],
      orgdata:[],
      itemval :"",
      alertmess : null,
      gemess :null,
      altdata : [],
      items : [],  
      messagedb:[],  
      showTop: false       
    };
  },
  methods: {
    ...mapActions("account", ["logout", "checktoken"]),
    ...mapActions("mact", ["insertmact"]),
    ...mapMutations('ws',['sendMessage','setWsMess']),
    ...mapActions('makeorg',['readorg','setorgval','getorglevel']),

    handleChange(value) {
          //console.log(`selected ${value}`);
          let temp =  this.orgdata.filter(rs => rs.id ==value)
          //console.log(this.orgdata)
          let athority = temp[0].acts_authority.find(rs => rs.Uid == this.user.data.user.id) 
          this.setorgval( {name: temp[0].name,id:temp[0].id ,state:temp[0].state,type:temp[0].type , permissions:athority  } )
        }, 
    async upload(){
        this.orgdata = this.datas;
        this.itemval = this.defaultval.name
    },   
    txtmess(val,i) {
      return val.substr(0,i)+"......"
    },
    
    loadmess(){
          let obj ={
            type:"load",
            pid:this.user.data.user.id,
            rmg: "",
            tomenss: [{id:this.user.data.user.id}]
            }
          this.sendMessage(JSON.stringify(obj))
    },
    revises(obj){
      let tmp = [];
      for(let i in obj){
        if(obj[i].vals!=='Chartreport'){
          if(obj[i].vals==='linecomment'){
            let Dtemp = obj[i].acts_detailpermission.filter(rs => rs.vals =='Line_control')
            obj[i].acts_detailpermission = Dtemp
            tmp.push(obj[i])
          }else{
            tmp.push(obj[i])
          }
        }
      }            
      return tmp.sort((a, b) => a.seq - b.seq);
    }    
  },
  computed: {
    ...mapState("account", ["status", "user"]), 
    ...mapState("makeorg", ["datas","defaultval","mylevel"]),
    ...mapState("ws", ["wsNotify","wsState","socket","wsMess","wsChang"]),
    Getmenus(){
      return this.revises(this.defaultval.permissions.acts_permissions)
    },

  },
  watch: {
      defaultval(val){
        this.itemval = val.name
        this.getorglevel( {id:this.defaultval.id} )
      },
      datas(){
        this.upload();       
      },
      wsNotify(val){
        this.altdata = [] 
        this.items =[]
        console.log(val)
        this.gemess =null
        this.alertmess=null        
              if(val){
                let {data} = val;
                //let tdata =null
                let itmes = {}  

                /*this.$notification['info']({
                  message: '訊息公告提示',
                  description:'請確認是否有公告/訊息, 尚未處理謝謝',
                  //style: getNotificationStyle('warning'),
                  duration: 1.5,
                });*/          


                  for(var obj in data) {
                      itmes =data[obj]
                        if(data[obj].typ==='mess'){
                            //console.log("------------",this.items.find(item => item.id == data[obj].id),"id:",data[obj].id)
                            //tdata = Number(this.gemess==null?0:this.gemess) +1
                            this.gemess =  "New"   
                            this.items.push(itmes);
                        }else if(data[obj].typ==='alert'){ 
                            //tdata = Number(this.alertmess==null?0:this.alertmess) +1
                            this.alertmess =  "New"           
                            this.altdata.push(itmes);
                        }  
                  }
                  this.messagedb =[]
                  for(var s in this.items){
                      this.messagedb.push(this.items[s])  
                  }
                  for(var k in this.altdata){
                      this.messagedb.push(this.altdata[k])  
                  }            
                  
                

                  var byDate = this.items.slice(0);
                  this.items=byDate.sort((a,b)=> {
                      return Date.parse(b.created_at) - Date.parse(a.created_at);
                  });

                  var byDate2 = this.altdata.slice(0);
                  this.altdata= byDate2.sort((a,b)=> {
                      return Date.parse(b.created_at) - Date.parse(a.created_at);
                  });
                  let tmpdata =[]
                  let i=0
                  for(var ob1 in this.items){
                      if(i<5){
                        tmpdata.push(this.items[ob1])  
                      }else if (i>5){
                        break
                      }            
                      i++
                  }
                  this.items = tmpdata  
                  let tmpdata2=[] 
                  let j=0       
                  for(var ob2 in this.altdata){
                      if(j<5){
                        tmpdata2.push(this.altdata[ob2])  
                      }else if (j>5){
                        break
                      }           
                      j++
                  }
                  this.altdata = tmpdata2
                  if(this.messagedb.length>0){
                    this.showTop = true;
                    setTimeout(() => {   
                        this.showTop = false;
                        clearInterval();
                    }, 4000);
                  }

                  
              }
      },

    wsState(val){
      if(val==true){
        this.loadmess()
      }
    },

    wsChang(val){
      let index=0
      //console.log(val)
      for (let itme in this.items) {
          if(this.items[itme].id==val){ 
            this.items.splice(index, 1);  
            if(this.items.length==0){
              this.gemess=null
            }                                           
            break;
          }
          index++
      }                
      index=0
      for (let itme in this.altdata) {
          if(this.altdata[itme].id==val){  
            this.altdata.splice(index, 1);  
            if(this.altdata.length==0){
              this.alertmess=null
            }
            break;
          }
          index++
      } 
      index=0
      for (let itme in this.messagedb) {
          if(this.messagedb[itme].id==val){ 
            this.messagedb.splice(index, 1);                          
            break;
          }
          index++
      }                  

    },

    messagedb(val){
      this.setWsMess({data: val}); 
    }

    },   
  mounted() {
    if (this.user == null) {
      router.replace("/");
    } else {
      this.checktoken();
    }
    if(this.wsState==true){
      this.loadmess()
    } 
    if(this.defaultval.id!==''){
      this.getorglevel( {id:this.defaultval.id} ) 
    } 
 
  },
  created() {
      this.upload();
      //console.log(this.defaultval)
  }
};
</script>
<style scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #7fbcf5;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
}

.ddic {
  border-radius: 16px;
  box-shadow: 1px 1px 15px -5px rgb(175, 174, 174);
  transition: all 0.3s ease;
}

.contents {
  background: #fff;
  box-shadow: 1px 1px 15px -5px rgb(175, 174, 174);
  padding: 0px;
}

.my-span {
  padding: 5px 10px; /* 調整內邊距 */
  border: 1px solid #bfdaf5; /* 設置邊線 */
  border-radius: 5px; /* 添加倒角 */
  background-color: #d6e9fc;
}


</style>